<template>
  <v-container fluid class="px-lg-10">
    <BaseTable
      ref="table"
      module="adminProjects"
      title="Progetti"
      :headers="headers"
      :actions="actions"
      sortby="key"
    >
      <template v-slot:item.key="{ item }">
        <v-chip color="primary" label> {{ item.key }}</v-chip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-chip v-if="item.active" color="success" small>ATTIVO</v-chip>
        <v-chip v-else color="gray" small>NON ATTIVO</v-chip>
      </template>
    </BaseTable>

    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title>Modifica Dettagli</v-card-title>
        <v-card-text>
          <ProjectsForm
            :title="null"
            :edit="true"
            @submitted="onSubmitted"
            @resetted="modalClose"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import ProjectsForm from '@components/admin/projects/ProjectsForm.vue'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'ProjectSearch',
  components: { ProjectsForm, BaseTable },
  page: {
    title: 'Progetti',
  },
  data() {
    return {
      headers: [
        {
          text: 'Codice Identificativo',
          align: 'start',
          value: 'key',
        },
        {
          text: 'Nome Iniziativa',
          value: 'name',
        },
        {
          text: 'PushGW ID',
          value: 'prefix',
        },
        {
          text: 'Cliente',
          value: 'customer',
        },
        {
          text: 'Attiva',
          value: 'active',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          color: 'primary',
          handler: this.openModal,
          onItemCondition: () => this.canUser('admin.projects', 'update'),
        },
      ],
      ctx: null,
      dialog: false,
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('adminProjects', ['getItems', 'update']),
    ...mapMutations('adminProjects', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      const startingPoint = item.key ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.key
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },

    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
